import type {
  Customer,
  CustomerAccessToken,
} from "@/graphql/generated/graphql-operations";
import type {
  ApiResponse,
  Call,
  Permissions,
  Subscription,
} from "@/types/subscription";
import * as Sentry from "@sentry/browser";

interface CustomerStore {
  customer: Customer;
  customerAccessToken: CustomerAccessToken;
}

export type State = {
  isActiveSubscriber: boolean;
  subscription: Subscription | null;
  permissions: Permissions | null;
  call: Call;
  loading: boolean;
  fetchSubscriptionStatus(): Promise<void>;
  init(): void;
};

const { get: getCustomer } = useLocalStorage();
const { customer } = window.theme;
const customerStore = getCustomer<CustomerStore>("customer");
const { EMR_BASE_URL } = useTheme();

const EMRMembership = axios.create({
  baseURL: `${EMR_BASE_URL}/MembershipV0`,
  headers: {
    Authorization: `Token ${customerStore?.customerAccessToken?.accessToken}`,
  },
});

async function fetchMembershipData() {
  if (!customer?.id) return;

  try {
    const { data } = await EMRMembership.get<ApiResponse>(
      `/membership?shopify_customer_id=${customer.id}`
    );

    return data;
  } catch (error: any) {
    Sentry.captureException(error);
  }
}

export const subscriptionStore: State = {
  isActiveSubscriber: false,
  subscription: null,
  permissions: null,
  call: {},
  loading: false,

  init() {
    this.fetchSubscriptionStatus();
  },

  async fetchSubscriptionStatus() {
    this.loading = true;

    try {
      const result = await fetchMembershipData();

      if (result) {
        this.isActiveSubscriber = result.membership?.active || false;
        this.subscription = result.membership?.subscription || null;
        this.permissions = result.membership?.permissions || null;
        this.call = result.membership?.call || {};
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
};
