import Alpine from "@/alpine";
import { PromoCodes } from "@/data/promocodes";
import app from "@/js/app";
import { cleanUpDiscounts } from "@/js/utils/discountsCleanup";
import { initSentryJS } from "@/js/lib/sentry/sentry-js";
import useTheme from "@/js/composables/useTheme";

const { IS_STAGING } = useTheme();

if (!IS_STAGING) {
  initSentryJS();
}
app.mount();
window.Alpine = Alpine;
Alpine.start();

/* spellchecker: disable */
cleanUpDiscounts([
  PromoCodes.Apoquel,
  PromoCodes.BravectoForCats,
  PromoCodes.BravectoForDogs,
  PromoCodes.CapstarFleaTreatment,
  PromoCodes.Clomicalm,
  PromoCodes.Credelio1Month,
  PromoCodes.Credelio3Months,
  PromoCodes.Credelio6Months,
  PromoCodes.CredelioCAT3Months,
  PromoCodes.CredelioCAT6Months,
  PromoCodes.EicosaDermOmega3Liquid,
  PromoCodes.FecalFloatWithGiardiaELISAKit,
  PromoCodes.FeliwayMultiCatStarterKit,
  PromoCodes.FeliwayOptimumDiffuserRefill,
  PromoCodes.FeliwayTravelSpray,
  PromoCodes.Galliprant,
  PromoCodes.Incurin,
  PromoCodes.MovoflexSoftChews,
  PromoCodes.NexGard1Month,
  PromoCodes.NexGard3Months,
  PromoCodes.NexGard6Months,
  PromoCodes.NutraMaxCosequinDS,
  PromoCodes.Purina,
]);
