import * as Sentry from "@sentry/browser";

const { customer, IS_STAGING } = useTheme();

const initSentryJS = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: IS_STAGING ? "staging" : "production",
    integrations: [Sentry.browserTracingIntegration()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event) => {
      if (customer) {
        event.user = { id: customer.id, email: customer.email };
      }
      return event;
    },
  });
};

export { initSentryJS };
