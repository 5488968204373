import type { CustomerQuery } from "@/graphql/generated/graphql-operations";
import useLocalStorage from "@/js/composables/useLocalStorage";
import useEMR from "@/js/lib/emr-api";
import { existy } from "@/js/utils/existy";
import type {
  ApiResponse,
  Call,
  Permissions,
  Subscription,
} from "@/types/subscription";
import * as Sentry from "@sentry/vue";
import dayjs from "dayjs";

interface SubscriptionState {
  isActiveSubscriber: boolean;
  subscription: Subscription | null;
  permissions: Permissions | null;
  call: Call;
  loading: boolean;
}

const MESSAGING_WINDOW_DURATION = 10;
const ACTIVE_MEMBERSHIP_TAGS = ["membership_status_tmp:active"];
const MONTHLY_SUBSCRIPTION_PRODUCT_TITLE = "Unlimited Vet Advice";

const subscriptionState = reactive<SubscriptionState>({
  isActiveSubscriber: false,
  subscription: null,
  permissions: null,
  call: {},
  loading: false,
});

const remainingDaysToMessage = computed(() => {
  if (!subscriptionState.call?.end_time) return 0;

  const callEndDate = dayjs(subscriptionState.call.end_time);
  const currentDate = dayjs();
  const daysSinceCall = currentDate.diff(callEndDate, "day");

  return Math.max(MESSAGING_WINDOW_DURATION - daysSinceCall, 0);
});

const isSubscriptionExpiring = computed(
  () => !!subscriptionState.subscription?.expiring_at
);

const isSubscriptionExpired = computed(
  () =>
    !subscriptionState.subscription?.expiring_at &&
    !subscriptionState.isActiveSubscriber
);

export default function useSubscription() {
  const { EMRMembership: membershipApi } = useEMR();
  const { customer: themeCustomer } = useTheme();
  // Use localStorage for customer data to ensure availability in non-Vue contexts
  const storefrontCustomer = useLocalStorage().get<CustomerQuery | null>(
    "customer"
  );

  const isDataFetched = computed(
    () =>
      existy(subscriptionState.isActiveSubscriber) &&
      existy(subscriptionState.permissions)
  );

  const isSubscriptionReady = computed(
    () =>
      subscriptionState.isActiveSubscriber &&
      Boolean(subscriptionState.subscription?.recharge_customer_id)
  );

  const hasActiveSubscriberTag = computed(() =>
    ACTIVE_MEMBERSHIP_TAGS.some(
      (key) =>
        themeCustomer?.tags.includes(key) ||
        storefrontCustomer?.customer?.tags.includes(key)
    )
  );

  const fetchMembershipData = async () => {
    if (!themeCustomer?.id) return;

    try {
      return await membershipApi.get<ApiResponse>(
        `/membership?shopify_customer_id=${themeCustomer.id}`
      );
    } catch (error: unknown) {
      Sentry.captureException(error);
    }
  };

  const initializeMembership = async () => {
    if (subscriptionState.loading || isDataFetched.value) return;
    await refreshMembershipStatus();
  };

  const refreshMembershipStatus = async () => {
    subscriptionState.loading = true;

    try {
      const { data } = (await fetchMembershipData()) || {};
      const membership = data?.membership;

      subscriptionState.isActiveSubscriber = membership?.active || false;
      subscriptionState.subscription = membership?.subscription || null;
      subscriptionState.permissions = membership?.permissions || null;
      subscriptionState.call = membership?.call || {};

      if (membership?.subscription && subscriptionState.subscription) {
        subscriptionState.subscription.monthly =
          membership.subscription.product_title ===
            MONTHLY_SUBSCRIPTION_PRODUCT_TITLE &&
          !membership.subscription.annual;
      }

      handleTemporaryMemberAccess();
    } catch (error) {
      console.error(error);
    } finally {
      subscriptionState.loading = false;
    }
  };

  const toggleSubscriptionAutoRenew = async (action: "enable" | "disable") => {
    try {
      subscriptionState.loading = true;
      const { data } = await membershipApi.post<ApiResponse>(
        `/membership/renewal/${action}`
      );

      return data;
    } catch (error: unknown) {
      Sentry.captureException(error);
    } finally {
      subscriptionState.loading = false;
    }
  };

  const handleTemporaryMemberAccess = () => {
    if (isSubscriptionReady.value) return;

    subscriptionState.isActiveSubscriber = hasActiveSubscriberTag.value;

    if (subscriptionState.permissions) {
      subscriptionState.permissions.book_a_vet_call = true;
    }
  };

  initializeMembership();

  return {
    ...toRefs(subscriptionState),
    daysLeftToMessage: remainingDaysToMessage,
    isSubscriptionExpiring,
    isSubscriptionExpired,
    isDataFetched,
    toggleSubscriptionAutoRenew,
    fetchSubscriptionStatus: refreshMembershipStatus,
  };
}
